@import 'variables/colors';

// Override Material Color
mat-progress-spinner.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, .mat-mdc-progress-spinner svg.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $heritage-red-100;
}

mat-progress-spinner.rdrx-spinner {
  circle {
    stroke: $heritage-red-100;
    stroke-linecap: round;
  }
}

mat-progress-spinner.rdrx-primary.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, .mat-mdc-progress-spinner svg.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $dark-blue-100;
}
  
mat-progress-spinner.rdrx-primary {
  circle {
    stroke: $dark-blue-100;
    stroke-linecap: round;
  }
}
