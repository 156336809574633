@import 'variables/colors';
@import 'theme-variables';

reader-core-search,
.reader-search {
  mhe-input.search-input {
    display: block;

    // textbox icon adjustment
    input {
      padding-left: 38px !important;
    }
  }

  // ngx-bonsai
  ngx-bonsai {
    > ul > li:first-of-type {
      border-top: 1px solid $gray-03;
    }
  }

  .highlighted {
    background-color: $dark-blue-100;
    color: $color-white;
    border-radius: 0.25em;
    padding: 0 0.1em;
    font-weight: 700;
  }
}

/** k5 theme */
.rdrx-theme-k5 reader-core-search {
  mhe-input.search-input {
    .form-control {
      font-size: 1.5rem;
      padding: 0.5em;
      height: unset;
      border-color: $k5-blue;
      color: $k5-gray;

      &:focus {
        box-shadow: 0 0 0 2px rgba($k5-blue, 0.8);
      }

      &:disabled {
        border-color: $k5-gray-light;
      }

      &::placeholder {
        color: rgba($k5-gray, 0.75);
      }
    }

    .dpg-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .highlighted {
    background-color: $k5-blue;
    color: #fff !important;
  }

  .loading-container {
    color: $k5-gray;

    h3 {
      color: inherit;
    }
  }
}

.loading-percent, .loading-message {
  color: $dark-blue-100;
}