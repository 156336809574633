@use "sass:math";
@import 'variables/colors';
@import 'src/scss/reader-features/theme-variables';
@import 'src/scss/mixins/node-active-indicator';

$tree-depth-sel: null;
$depth-pad: 0;
$depth-pad-increment: 1em;

ngx-bonsai.rdrx {
  /** tree nodes */
  > ul > li {
    > div.node-wrapper {
      &:focus-within,
      &:hover {
        .rdrx-node-content {
          text-decoration: none;
        }
      }
    }
  }
  .node-wrapper {
    min-height: 70px;
    padding-left: math.div($depth-pad-increment, 2);
    border: none;
    border-bottom: 1px solid $gray-03;
    position: relative;

    &:focus-within,
    &:hover {
      outline: unset; // unset browser default
      cursor: pointer;

      &::after {
        @include node-active-indicator();
      }

      .rdrx-node-content {
        outline: unset; // unset browser default
        text-decoration: underline;
      }
    }

    .toggle-children-wrapper,
    .node-content-wrapper {
      position: relative;
      z-index: 2;
    }

    .node-content-wrapper {
      border: none !important; // :(
      background-color: transparent !important;
    }

    &.node-active {
      &::before {
        @include node-active-indicator;
      }

      &::after {
        @include node-active-indicator(4px);
      }
    }
  }

  /** tree indentation */
  ul.tree-children {
    padding: 0;
  }

  ul li {
    margin: 0 !important; // :(
  }

  /** depth indent */
  @for $i from 1 through 10 {
    $tree-depth-sel: if(
      $i == 1,
      "ul.tree-children",
      selector-nest($tree-depth-sel, "ul")
    );
    $depth-pad: if(
      $i == 1,
      math.div($depth-pad-increment, 2),
      $depth-pad-increment * ($i)
    );

    #{$tree-depth-sel} .node-wrapper {
      padding-left: $depth-pad;
    }
  }

  /** collapsible icon */
  .toggle-children-wrapper {
    border: none;
    background-color: transparent;

    .toggle-children {
      border: 1px solid $dark-blue-100;
      border-radius: 1em;

      &::before {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  /** content links */
  a,
  a.ahe-ui-link {
    text-decoration: none;
    color: $dark-blue-100;
    border-color: transparent;
  }
}

.rdrx-theme-k5 ngx-bonsai.rdrx {
  /** tree nodes */
  .node-wrapper {
    border-bottom-color: $k5-gray-light;
    min-height: 80px;

    &:focus-within,
    &:hover {
      &::after {
        border-color: $k5-blue;
      }
    }

    &.node-active {
      &::before,
      &::after {
        border-color: $k5-blue;
      }

      &,
      .node-content-wrapper {
        background-color: rgba($k5-blue, 0.2);
      }
    }

    .toggle-children-wrapper,
    .toggle-children-placeholder {
      font-size: 2rem;
      width: 1.25em;
      margin-right: 3px;
      flex: 0 0 auto;
    }

    .toggle-children-wrapper {
      .toggle-children {
        width: 1.25em;
        height: 1.25em;
        border: none;

        &::before {
          content: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M10.343 6.343L16 12l-5.657 5.657-1.414-1.414L13.172 12 8.929 7.757z'/%3E%3C/svg%3E");
          width: 1.25em !important;
          height: 1.25em !important;
        }
      }
    }
  }

  /** root nodes */
  .node-wrapper[aria-level="1"] {
    background-color: $k5-blue;

    a.ahe-ui-link,
    span {
      text-decoration: none;
      color: #fff;
    }
  }

  /** content links */
  a,
  a.ahe-ui-link,
  span {
    text-decoration: none;
    color: $k5-gray;
    font-weight: 500;
  }
}
