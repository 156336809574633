@import 'variables/colors';

// NEW IMPLEMENTATION

mat-slider.mat-mdc-slider.mat-primary {
  --mdc-slider-focus-handle-color: $gray-16;
}

// TODO convert to SCSS
// TODO clean after approval before merge
// mat-slider.mat-mdc-slider .mdc-slider__track {
//   height: 2px;
// }
mat-slider.mat-mdc-slider .mdc-slider__track--inactive {
  // height: 2px;
  background-color: $gray-16;
}
mat-slider.mat-mdc-slider .mdc-slider__track--active {
  // height: 3px;
  background-color: $gray-06;
}
mat-slider.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: $gray-16;
}
mat-slider.mat-mdc-slider .mdc-slider__thumb-knob {
  width: 15px;
  height: 15px;
  background-color: $gray-16;
  border-color: $gray-16;
  border-width: 0;
}
mat-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob  {
  width: 20px;
  height: 20px;
  background-color: $gray-16;
  border-color: $gray-16;
  border-width: 0;
}
mat-slider.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple {
  width: 28px !important;
  height: 28px !important;
  transition-duration: 250ms !important;
  transform: translate(10px, 10px) scale3d(1, 1, 1) !important;
}
mat-slider.mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple {
  width: 32px !important;
  height: 32px !important;
  border: 2px solid $gray-16;
  transition-duration: 250ms !important;
  transform: translate(8px, 8px) scale3d(1, 1, 1) !important;
}
mat-slider.mat-mdc-slider .mat-ripple .mat-mdc-slider-hover-ripple {
  background-color: transparent;
}
// mat-slider.mat-mdc-slider .mdc-slider__tick-mark--active, mat-slider.mat-mdc-slider .mdc-slider__tick-mark--inactive {
//   width: 0;
//   height: 0;
// }

// OLD IMPLEMENTATION

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
mat-slider {
  /* accessability */
  &.mhe-a11y {
    &.cdk-focused {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): DEPRECATED */
      .mat-slider-focus-ring {
        border: 1px solid;
        // match styles for {keyboard,program}-focused
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &.mdc-slider {
    .mdc-slider__thumb.mdc-slider__thumb--with-indicator {
      .mdc-slider__value-indicator {
        width: max-content;
      }
    }
  }

  // "standard" for rdrx
  &.rdrx-slider {
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    // mat-slider-track DEPRECATED
    // TODO DEPRECATED
    .mat-slider-track-wrapper {
      border-radius: 1em;
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      // TODO DEPRECATED
      .mat-slider-track-background {
        background-color: $gray-06;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      // TODO DEPRECATED
      .mat-slider-track-fill {
        background-color: $gray-16;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    // mat-slider-thumb-container DEPRECATED
    .mat-slider-thumb-container {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb {
        background-color: $gray-16;
      }
    }

    /** track size */
    $mat-slider-container: 48px;
    $trackSize: 4px;
    $track-wrapper-offset: ($mat-slider-container - $trackSize) / 2;

    // horizontal
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    // mat-slider-thumb-container DEPRECATEDllowing rule targets internal classes of slider that may no longer apply for the MDC version. */
    &.mat-slider-horizontal {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-wrapper {
        height: $trackSize;
        top: $track-wrapper-offset;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      // TODO DEPRECATED
      .mat-slider-track-wrapper {
        height: $trackSize;
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        // TODO DEPRECATED
        .mat-slider-track-fill {
          height: $trackSize;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        // TODO DEPRECATED
        .mat-slider-track-background {
          height: $trackSize / 2;
          top: $trackSize / 4;
        }
      }
    }

    // vertical
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    // mat-slider-vertical DEPRECATED
    &.mat-slider-vertical {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-wrapper {
        width: $trackSize;
        left: $track-wrapper-offset;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      // TODO DEPRECATED
      .mat-slider-track-wrapper {
        width: $trackSize;
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        // TODO DEPRECATED
        .mat-slider-track-fill {
          width: $trackSize;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        // TODO DEPRECATED
        .mat-slider-track-background {
          width: $trackSize / 2;
          left: $trackSize / 4;
        }
      }
    }

    // +a11y
    &.mhe-a11y {
      &.cdk-focused {
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        // mat-slider-focus-ring DEPRECATED
        .mat-slider-focus-ring {
          border-color: $gray-16;
          border-width: 2px;
        }
      }
    }
  }
}
