.reader-dialog,
.rdrx-large-img-dialog {
  rdrx-modal-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mat-mdc-dialog-container {
    padding: unset;
  }
}

reader-core-reader {
  .cdk-overlay-container .cdk-global-overlay-wrapper {
    display: flex !important;
  }
}

.font-resizer-dialog-component {
  .mdc-dialog {
    .mdc-dialog__content {
      padding: 0;
    }
  }
}
