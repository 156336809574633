@import 'variables/colors';
@import 'src/scss/reader-features/theme-variables';
@import 'src/scss/mixins/node-active-indicator';

/** rdrx standard */
reader-core-reader:not(.rdrx-theme-k5) {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-tab-group.mhe-a11y {
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */

    // CLASS NAME CHANGED IN NEW VERSION
    .mdc-tab {
      margin: 4px;

      // hover state
      &:hover {
        &::after {
          @include node-active-indicator;
          border-width: 1px;
        }
      }

      // focus state
      &.cdk-focused {
        overflow: visible;

        &::before {
          @include node-active-indicator(-9px);
          background-color: $color-white;
        }

        &::after {
          @include node-active-indicator;
          border-width: 1px;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        &:not(.mat-tab-disabled) {
          &.cdk-program-focused,
          &.cdk-keyboard-focused {
            background-color: unset;
          }
        }
      }

      // shared hover/focus content
      &:hover,
      &.cdk-focused {
        /*TODO(mdc-migration): The following/ rule targets internal classes of tabs that may no longer apply for the MDC version. */
        // mat-tab-label-content DEPRECATED
        .mat-tab-label-content {
          z-index: 2;
        }
      }

      // content
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      // mat-tab-label-content DEPRECATED
      .mat-tab-label-content {
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;
      }

      // interaction active state
      &:active {
        &::after {
          background-color: $gray-02;
        }
      }

      // active tab state
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      &.mat-tab-label-active {
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-label-content {
          border-bottom: 4px solid $gray-14;
          padding-top: 4px;
        }
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-tab-group.rdrx-tabs {
    .mat-mdc-tab-body-wrapper {
      height: 100%;
    }
    .mat-mdc-tab-body {
      height: 100%;
    }
    //.mat-mdc-tab-header {
    //  border-bottom: none;
    //
    //  &::after {
    //    content: '';
    //    height: 2px;
    //    width: 100%;
    //    background-color: $gray-07;
    //    position: absolute;
    //    bottom: 2px;
    //    left: 0;
    //  }
    //}
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-list {
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label {
        opacity: 1;
      }

      .mat-ink-bar {
        display: none;
        transition: none;
      }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label {
      .rdrx-k5-icon {
        display: none;
      }
    }
  }
}

/** k5 theme */
.rdrx-theme-k5 mat-tab-group {
  .mat-mdc-tab {
    background-color: $color-white;
    display: flex;
    align-items: center;
    font-size: inherit;
    height: 4.5em;

    .mdc-tab__content {
      border: 4px solid $k5-gray;
      border-radius: 10px;
      height: 3.6em;
    }

    mat-icon {
      width: 3em;
      height: 3em;
      padding: 0.25em;
      margin: 0.1em;
      border-radius: 5px;
      display: flex;
    }

    .dpg-icon {
      display: none;
    }

    // hover state
    &:hover {
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label-content {
        border-color: $color-white;
      }

      mat-icon {
        background-color: $k5-blue;
        color: $color-white;
      }
    }

    // focus state
    &.cdk-focused {
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      &:not(.mat-tab-disabled) {
        &.cdk-program-focused,
        &.cdk-keyboard-focused {
          background-color: unset;
          /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mdc-tab__content {
            position: relative;

            &::after {
              @include node-active-indicator(-8px);
              border-radius: 10px;
              border-color: $k5-gray;
            }
          }
        }
      }
    }

    // shared hover/focus content
    &:hover,
    &.cdk-focused {
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label-content {
        z-index: 2;
      }
    }

    // interaction active state
    &:active {
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mdc-tab__content {
        border-color: $k5-gray-light;
      }

      mat-icon {
        background-color: rgba($k5-blue, 0.9);
        color: #fff;
      }
    }

    // active tab state
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mdc-tab--active {
      mat-icon {
        background-color: $k5-blue;
        color: #fff;
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-list {
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab {
      opacity: 1;
    }

    .mat-ink-bar {
      display: none;
      transition: none;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
}

/** shared */
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group.flex-labels {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    min-width: unset;
    flex: 1 1 auto;
    padding: 0;
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content > div {
      display: inline-flex;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group.hide-tabs {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-tab-header {
    display: none;
  }
}
