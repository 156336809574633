@import 'src/scss/mixins/breakpoints';

/** max content-width */
reader-core-reader {
  --rdrx-max-content-width: 100%;

  /**
   * In the standard mode the ePub content area should take up 100% of the available width.
   * At the parent level is a flexbox layout and the AI Reader will occupy a fixed width within it.
   *
   * In the scenario where a viewport is wider than 1200px the ePub content area will be capped to
   * that width and then center itself within the space from the left to the right, up against
   * the AI Reader component if it is visible.
   */
  .rdrx-content-container {
    width: 100%;
    max-width: var(--rdrx-max-content-width);
    margin: 0 auto;
  }

  @include media-breakpoint-up('xl') {
    --rdrx-max-content-width: 1200px;
  }
}

/**
 * Full-width override
 *
 * custom_content_width = "full"
 */
reader-core-reader.full-width {
  --rdrx-max-content-width: 100%;

  .rdrx-content-container {
    width: 100% !important;
  }
}
