.dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.dialog-heading {
    font-size: large;
    font-weight: bold;
    color: $color-white;
    margin: 0;
}
