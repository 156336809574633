@import 'scss/styles.scss';
@import 'scss/overrides/index';
@import 'scss/overrides/material/mat-overrides';

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
