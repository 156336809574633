@import 'variables/colors';
@import 'theme-variables';

rdrx-contents-menu {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-header {
    padding: 0 var(--rdrx-drawer-inset-pad, 1em);
    border-bottom: 1px solid $gray-03;
  }

  .mat-mdc-tab-body-content {
    padding: 0 var(--rdrx-drawer-inset-pad, 1em);
  }

  .mat-mdc-tab-group {
    font-family: 'proxima_novaregular' !important;
  }
}

.annotation-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 0.5em;
    border-bottom: 1px solid $gray-03;

    h3 {
      margin: 0;
    }

    &.annotation-list-empty {
      align-items: center;
      justify-content: center;
      border: none;
    }
  }

  .list-empty-msg {
    text-align: center;
  }

  .annotation-item {
    display: flex;
    width: 100%;
    align-items: center;

    .title {
      flex: 1 0 auto;
      padding: 0 10px;
      width: min-content;
      color: $dark-blue-100;
    }

    .date {
      display: inline-block;
      margin-right: 0.33em;
      font-family: 'proxima_novaregular_bold';
    }

    .rdrx-truncate-toggle {
      padding-left: 0;
      text-decoration: underline;
      font-family: 'proxima_novaregular_bold';
    }

    .trashcan {
      flex: 0 0 auto;
    }
  }
}

/** k5 theme */
.rdrx-theme-k5 rdrx-contents-menu {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-header,
  .mat-mdc-tab-body-content {
    padding: 0;
  }
}

.rdrx-theme-k5 .annotation-list {
  .header {
    font-size: 1.25rem;
    height: 3em;
    padding-left: var(--rdrx-drawer-inset-pad);
    padding-right: var(--rdrx-drawer-inset-pad);

    h3 {
      color: $k5-gray;
      font-size: inherit;
    }
  }

  .list-empty-msg {
    color: $k5-gray;
  }
}
