@import 'variables/colors';

reader-core-toolbar {
  --toolbar-btn-gap: 0.3em;
  --toolbar-icon-size: 1.25em;
  --toolbar-btn-width: 1.75em;

  color: $dark-blue-100;

  /** shared button */
  button.ngx-shared.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    padding: 0;
    width: var(--toolbar-btn-width);
    margin: 0 var(--toolbar-btn-gap);

    .dpg-icon,
    .ahe-icon {
      padding-top: unset;
      display: flex;
      align-items: center;
      width: var(--toolbar-icon-size, 1.25em);
      height: var(--toolbar-icon-size, 1.25em);

      &::before {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  /** child container positioning */
  reader-core-navigation {
    margin: 0 var(--toolbar-btn-gap);

    reader-core-position-indicator {
      padding: 0 0.33em;

      > span {
        max-width: 3.5em;
      }
    }
  }

  .actions {
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  .rdrx-content-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  @include media-breakpoint-up(sm) {
    --toolbar-btn-gap: 0.33em;
    --toolbar-icon-size: 1.5em;
    --toolbar-btn-width: 2.25em;

    /** child container positioning */
    reader-core-navigation {
      reader-core-position-indicator {
        padding: 0;

        > span {
          max-width: unset;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
