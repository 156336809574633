@import 'variables/colors';

reader-core-reader {
  .annotations-overlay-panel {
    background-color: white;
    border-radius: 10px;

    &.annotations-overlay-panel-arrow-up {
      &::after {
        position: absolute;
        bottom: calc(100% - 1px);
        left: calc(50% - 10px);
        height: 20px;
        width: 20px;
        content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20"><polygon points="0,20 20,20 10,10" style="fill:white" /></svg>');
      }
    }

    &.annotations-overlay-panel-arrow-down {
      &::after {
        position: absolute;
        top: calc(100% - 1px);
        left: calc(50% - 10px);
        height: 20px;
        width: 20px;
        content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20"><polygon points="0,0 20,0 10,10" style="fill:white" /></svg>');
      }
    }
  }
}

// this class is on the reader-core-reader parent element
reader-core-reader.ai-assist-enabled {
  // if ai reader is enabled, add a box shadow to the context menu
    .annotations-overlay-panel {
      box-shadow: 3px 3px 7px #515151;
      &.annotations-overlay-panel-arrow-up {
        &::after {
          content: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="0 0 20 20"%3E%3Cdefs%3E%3Cfilter id="shadow" x="-50%" y="-50%" width="200%" height="200%"%3E%3CfeDropShadow dx="0" dy="-1" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.5)"%3E%3C/feDropShadow%3E%3C/filter%3E%3C/defs%3E%3Cpolygon points="0,20 20,20 10,10" style="fill:white; filter:url(%23shadow);"%3E%3C/polygon%3E%3C/svg%3E');
        }
      }
      &.annotations-overlay-panel-arrow-down {
        &::after {
          content: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="0 0 20 20"%3E%3Cdefs%3E%3Cfilter id="shadow" x="-50%" y="-50%" width="200%" height="200%"%3E%3CfeDropShadow dx="0" dy="-1" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.5)"%3E%3C/feDropShadow%3E%3C/filter%3E%3C/defs%3E%3Cpolygon points="0,0 20,0 10,10" style="fill:white; filter:url(%23shadow);"%3E%3C/polygon%3E%3C/svg%3E');
      }
    }
    }
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.modal-header {
  font-size: medium;
  font-weight: bold;
  color: $color-white;
  margin: 0;
  border-bottom: 0px;
}
