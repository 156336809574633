@import 'variables/colors';
.glossary-pronunciation {
  font-family: 'proxima_novaregular';
  font-weight: normal;
  margin-right: 10px;
  color: $sky-blue-120;
  &::before,
  &::after {
    content: '/';
  }
}
.glossary-headword {
  font-size: 16px;
  font-family: "proxima_novaregular_bold";
  color: $sky-blue-120;
}

.audio-pronunciation {
  display: none;
}

.glossary-images {
  display: flex;
  margin-top: 10px;
  figure {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  figcaption {
    display: none;
  }
}
.glossary-images,
.glossary-media {
  img,
  video {
    display: block;
    max-width: 307px;
    width: 100%;
    object-fit: contain;
    height: auto;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
}
