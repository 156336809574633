@import 'src/scss/mixins/breakpoints';

reader-core-reader {
  .epub-alerts {
    margin-right: 5em;
    margin-left: 0.25em;

    mhe-alert {
      display: block;
      padding-top: 0.5em;

      .alert {
        padding-right: 2em;
        margin-bottom: 0.5em;

        &::before {
          display: inline-flex;
          flex-flow: column nowrap;
          justify-content: space-around;
        }

        .close {
          width: 2em;
          height: 1.5em;
        }
      }
    }

    .alert-count {
      font-style: italic;
      font-weight: 700;
      letter-spacing: 0.1em;
      margin: 0 0.25em;
    }

    @include media-breakpoint-up(md) {
      width: clamp(50vmin, 80%, 1000px);
      margin: 0 auto;
    }
  }
}
