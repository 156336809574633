@import 'variables/colors';


.contents-dropdown {
  .mat-mdc-menu-content {
    margin: 0;
    padding: 0 !important;
  }

  &.mat-mdc-menu-panel {
    margin-top: 0.25rem;
    border: 1px solid $gray-12;
  }

  .mat-mdc-menu-item {
    &:hover {
      outline: 1px solid $gray-14;
      outline-offset: -1px;
    }
  
    &:focus {
      background-color: $gray-12 !important;
      color: white !important;
    }
  }
}