@import 'variables/colors';

.rdrx-dropdown-menu {
  .ahe-ui-dropdown .list-items-container li .ahe-ui-checkbox > input[type='checkbox'] {
    &:not(:checked) {
      + span,
      + div {
        &::before {
          content: '';
        }
      }
    }
  }

  .ahe-ui-dropdown,
  .dpg-ui-dropdown {
    .dropdown-menu li.option-group-title .dropdown-item input[type='checkbox'] + span {
      color: $gray-07;
    }
  }
}
