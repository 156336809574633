@import 'variables/colors';

// border/outline style wtih pseudo elements
@mixin node-active-indicator($offset: 0) {
  content: '';
  display: block;
  position: absolute;
  top: $offset;
  bottom: $offset;
  left: $offset;
  right: $offset;
  border: 2px solid $gray-14;
  z-index: 1;
}
