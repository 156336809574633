@import 'variables/colors';

h3 {
    color: $gray-12;
}

p {
    color: $gray-12;
    font-family: "proxima_novaregular";
    font-size: 16px;
}

.btn-tertiary:disabled, .btn-tertiary.disabled, .btn-icon:disabled, .btn-icon.disabled {
    border: 1px solid rgba(0,0,0,0);
}

mhe-checkbox .ahe-ui-checkbox {
    color: $gray-12;
}

button.btn-arrow-icon {
    margin: 5px 5px;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    color: $sky-blue-140;
    font-size: 16px;
    height: 36px;
    padding: 0 8px;
    transition: background-color 0.2s ease-in-out;
    border: 1px solid transparent;

    &:not([disabled]):hover {
        color: $sky-blue-140;
        border: 1px solid $sky-blue-140;
    }

    &:disabled {
        color: red;
        border: 0;
    }
    &.disabled {
        color: red;
        border: 0;
    }
}

.alert {
    background-color: #f0f3f4;
    background-repeat: no-repeat;
    border-radius: 4px 0 4px 4px;
    border-style: none;
    color: #46707d;
    font-size: 14px;
    line-height: 1.25;
    padding: 16px 36px 16px 60px;
    position: relative;
}
