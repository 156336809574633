@import 'variables/colors';

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
mat-progress-bar.rdrx-bar {
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  // DEPRECATED
  .mat-mdc-progress-bar-background,
  .mat-mdc-progress-bar-buffer {
    background-color: rgba($heritage-red-100, 0.2);
  }

  .mdc-linear-progress {

    &__bar-inner {
      border-color: $heritage-red-100;
    }

    &__buffer {
      background-color: rgba($heritage-red-100, 0.2);
    }

    &__primary-bar {
      background-color: $heritage-red-100;
    }

    &__secondary-bar {
      background-color: rgba($heritage-red-100, 0.5);
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  // DEPRECATED
  .mat-mdc-progress-bar-fill::after {
    background-color: $heritage-red-100;
  }
}
