@import 'variables/colors';
@import 'src/scss/reader-features/theme-variables';

reader-core-reader {
  --rdrx-drawer-inset-pad: 1.25em;
  --rdrx-drawer-header-height: 52px;

  // mobile first approach for drawer size
  .rdrx-drawer {
    width: 95vw;

    @include media-breakpoint-up(sm) {
      width: 500px;
    }
  }

  .drawer-container {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    .drawer-header {
      align-items: center;
      background-color: $dark-blue-40;
      display: flex;
      flex-flow: row nowrap;
      height: var(--rdrx-drawer-header-height);
      padding: 0 var(--rdrx-drawer-inset-pad);

      h2 {
        color: $dark-blue-100;
        font-size: 18px;
        margin: 0;
      }

      button {
        flex: 0 0 auto;
        margin-left: auto;
      }
    }

    .drawer-body {
      flex: 1 1 100%;
      overflow-y: auto;
    }
  }
}

/** k5 theme */
reader-core-reader.rdrx-theme-k5 {
  --rdrx-drawer-header-height: 60px;

  .drawer-container {
    font-size: 1.25rem;
    color: $k5-gray;

    .drawer-header {
      background-color: $k5-blue;

      h2 {
        font-size: 1.5rem;
        color: #fff;
      }

      button.ngx-shared.btn-icon {
        font-size: 1.5rem;
        width: 1.75em;
        height: 1.75em;

        .dpg-icon {
          width: 0.75em;
          height: 0.75em;
        }

        &:hover,
        &:focus {
          border-color: #fff;
        }
        &:focus::before {
          box-shadow: 0 0 0 2px #fff;
        }

        .dpg-icon-system-close::before {
          content: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='%23ffffff' d='M15.266.66q-.202-.197-.53-.348-.333-.151-.694-.215-.387-.066-.713-.011-.362.061-.592.261-.365.321-2.572 2.585-1.106 1.134-2.16 2.225Q6.948 4.063 5.84 2.926 3.629.661 3.263.34q-.23-.202-.591-.261-.326-.055-.713.011-.362.063-.693.215-.33.151-.532.347-.269.261-.452.61-.176.333-.244.687-.068.355-.011.653.057.311.24.502.499.521 4.856 4.933L.371 12.832q-.092.092-.166.34-.082.282-.089.609-.014.873.513 1.525.262.325.695.487.365.137.815.147.376.008.713-.07.306-.071.395-.163l4.75-4.714 4.756 4.723q.09.09.395.161.339.078.715.07.449-.009.814-.147.434-.16.695-.486.527-.654.513-1.526-.006-.328-.089-.609-.073-.247-.166-.34l-4.752-4.795q4.358-4.411 4.858-4.932.183-.191.24-.503.056-.298-.013-.653-.066-.354-.243-.687-.184-.349-.453-.609z' /%3E%3C/svg%3E");
        }
      }
    }
  }
}

.go-to-active {
  height: 52px;
  padding: 1em 0.5em;
  border-bottom: 1px solid $gray-03;
  color: $dark-blue-100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;

  strong {
    font-weight: 500;
  }

  .ahe-ui-link {
    color: inherit;
    border-color: transparent;
    font-size: 0.9em;
    text-decoration: none;
  }

  &:focus,
  &:hover {
    outline: unset;

    .ahe-ui-link {
      text-decoration: underline;
    }
  }
}
